$(document).ready(function () {
    common();
    goToTop();
    jumpMenu();
    bannerSlider();
    // productDetailSlider();
    setTopMenuTitleFromBlogView();
    blogSharing();
    // productSharing();
    loadGoogleFont();
    beerSliderOfBlogView();
    // lazyMap();
    initOwlShowcases();

    appWidgetFeed($('.elfsight-app'));
    new WOW().init();
    autoGenerateRwdTableInEditor();

});

//b版型的id不太一樣
function goToTop() {
    var $scrollToTop = $('#scroll_totop');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $scrollToTop.fadeIn();
        } else {
            $scrollToTop.fadeOut();
        }
    });

    //Click event to scroll to top
    $scrollToTop.click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}

function jumpMenu() {

    //選單跳段落
    $('.nav a').click(function () {

        console.log('click menu link');

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash, -$('.header').height(), 500);
        }
        // return false;
    });


    /*才能避免slider產生後的高度讓跳cut不正確*/
    var jumpHash = location.hash;
    //超連結跳段落
    if (jumpHash !== '') {
        var newjumpHash = jumpHash.replace(/\//, '');
        console.log('detect jump from link url:' + newjumpHash);
        Pace.on('done', function () {
            // jumpSection(newjumpHash);
            jumpSection(newjumpHash, -$('.header').height(), 500);
        });
    }


}

function bannerSlider() {
    var bSlider = null;
    // Pace.on('done', function() {
    var $bannerSlider = $('#bannerSlider');
    if ($bannerSlider.length > 0) {

        setMasterSliderImageOnScreen($bannerSlider);

        try {
            bSlider = new MasterSlider();
            // adds Arrows navigation control to the slider.

            bSlider.control('timebar', {
                insertTo: '#bannerSlider'
            });
            // bSlider.control('bullets');

            // bSlider.control('circletimer', { autohide: false, overVideo: true, color: '#FFFFFF', radius: 4, stroke: 9 });

            bSlider.control('arrows', {
                autohide: true
            });

            bSlider.setup('bannerSlider', {
                width: 1440, // slider standard width
                height: 720, // slider standard height
                minHeight: 400,
                start: 1,
                space: 0,
                layout: 'fullwidth',
                loop: true,
                preload: 0,
                instantStartLayers: false,
                autoplay: true,
                overPause: true,
                view: 'fadeBasic'
            });
            // $(window).trigger('resize');
            $('.master-skeleton-loader').remove();

        } catch (err) {
            console.log(err);
            removeErrorMasterSliderContainer($bannerSlider);
        }
    }
    // });
}


//用於blog頁面
function beerSliderOfBlogView() {
    $.fn.BeerSlider = function (options) {
        options = options || {};
        return this.each(function () {
            new BeerSlider(this, options);
        });
    };

    var $beerSlider = $('.beer-slider');
    if ($beerSlider.length > 0) {


        $beerSlider.each(function (index, el) {

            var $beerEl = $(el);

            console.log('(' + index + ') bs slider init');
            console.log($beerEl);
            $beerEl.data('bsindex',index);

            //init beerslider
            $beerEl.BeerSlider();

            $beerEl.data('bsinit', false);

            $beerEl.on('inview', function (event, isInView) {
                console.log('bs inview (' + $beerEl.data('bsindex') + ')');
                var $beerElTmp = $(this);
                var $imgsOfBeer = $beerElTmp.find('.beer-reveal img');

                if(isInView && $beerElTmp.data('bsinit')==false){
                    $beerElTmp.data('bsinit', true);
                    //觸發所有圖片
                  
                    $imgsOfBeer.load(function(){
                        var $img = $(this);
                        $img.css('width',$beerElTmp.width());
                    });
                    $imgsOfBeer.attr('src',$imgsOfBeer.attr('data-nolazy'));
                    $imgsOfBeer.remove('data-nolazy')
                    $imgsOfBeer.addClass('no-autowidth');
                    // alert('resize');
                }

                //每次初始化還是再檢查有沒有圖片的width是0px或1px
                console.log('bs inview (' +  $beerElTmp.data('bsindex') + ') 初始化完：' + $imgsOfBeer.attr('src'));
                if($imgsOfBeer.css('width') == '0px' || $imgsOfBeer.css('width')  == '1px'){
                    console.log('reset width when detect 0/1px');
                    $imgsOfBeer.css('width',$beerElTmp.width());
                }
            });



        });

    }
}

function setTopMenuTitleFromBlogView() {

    var $logolink = $('.header_top').find('.logolink-scroll');
    if ($('.post-detail-row').length > 0) {

        $logolink.hide();
        $('#page-title').show().html($('.post-body h1').html());

    } else {
        $logolink.show();
    }
}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

// function productSharing() {
//     initJsSocial($('#productSharing'));
// }

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["twitter", "facebook", "linkedin"]
        });
    }
}

function loadGoogleFont() {
    Pace.on('done', function () {
        WebFont.load({
            timeout: 2000,
            google: {
                families: [
                    'Noto Sans TC:300,600,700,800&display=swap',
                    'Roboto&display=swap'
                ]
            }
        });
    });
}

// function lazyMap() {
//     Pace.on('done', function () {
//         initViewLazyOfIframe($('#homeMap'));
//     });
// }

//第三方iniview載入機制
function initViewLazyOfIframe($targetElem) {
    var isEnable = false;
    if ($targetElem.length > 0) {
        $targetElem.on('inview', function (event, isInView) {
            if (isInView) {
                if (!isEnable) {
                    $targetElem.attr('src', $targetElem.attr('data-url'));
                    isEnable = true;
                }
            }
        });
    }
}

function initOwlShowcases() {

    Pace.on('done', function () {
        owlSlider($('.showcase-boxes'), 3, 1, {
            autoWidth: false,
            center: true,
            responsiveRefreshRate: 0,
            responsive: {
                0: {
                    items: 1
                },
                767: {
                    items: 2
                },
                1024: {
                    items: 3,
                }

            }
        });
    });
}

function owlSlider($owlSlider, items, slideBy, extendOpts) {
    console.log('owlSlider');
    if ($owlSlider.length > 0) {

        var opts = {
            loop: true,
            items: items,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            margin: 20,
            autoplay: true,
            nav: true,
            dots: true,
            slideBy: slideBy
        }

        $.extend(opts, extendOpts);


        var $owlComp = $owlSlider.owlCarousel(opts);
        var $owlImages = $owlSlider.find('img');
        var isEnableLz = false;
        $owlImages.on('inview', function (event, isInView) {
            if (isInView && isEnableLz == false) {
                isEnableLz = true;
                startLoadImages($owlImages);

            }
        });

        return $owlComp;
    }

}

function appWidgetFeed($initLoadElemWrapper) {
    if ($initLoadElemWrapper.length > 0) {
        var isEnable = false;
        $initLoadElemWrapper.on('inview', function (event, isInView) {
            if (isInView && isEnable == false) {
                isEnable = true;
                var appWidgets = document.createElement("script");
                appWidgets.async = true;
                if (window.MYAPP.hasOwnProperty('apps_widget_endpoint')) {
                    appWidgets.src = window.MYAPP.apps_widget_endpoint;
                    $("head").append(appWidgets);
                }
            }


        });
    }
}
